export const configurations_and_personalized_support = {
    meta_title: "Your Fiber, Your Way: Niurix & Personalized Support",
    meta_description: "Expert guidance, endless possibilities. Experience the difference with custom fiber solution and personalized support from Niurix.",

    section1: {
        banner: "configurations_and_personalized_support_banner",
        banner_mobile: "Configurations and Personalized Support",
        alt:" Get expert help on fIber-optics configuration and support",
        title: "Configurations and Personalized Support",
    },

    section2: {
        heading: "Tailoring Solutions, <span>Ensuring Satisfaction</span>",
        para: "At Niurix, we blend our technical expertise with a deep dedication to understanding each customer's individual needs. While we offer top-notch GPON solutions, our promise goes beyond that. Niurix is committed t o providing exceptional support, ensuring that every user doesn't just get a product but a solution that truly fits their unique requirements and goals.",
        img: "configurations_and_personalized_support",
        alt:"Get configuration and personalized support with fiber optic",
    },

    section3: {
        card_heading: "Three Key Factors of Our <span>Configurations</span> and <span>Personalized Support</span>",
        cards: [
            {
                number: "01",
                title: "Customizable Configurations",
                para: "Easily adjust and personalize network settings to align perfectly with specific requirements.",
                img: "Customizable-Configurations",
            },
            {
                number: "02",
                title: "Dedicated Support",
                para: "Our dedicated support team ensures that every question, concern, and challenge is addressed with promptness and expertise.",
                img: "Dedicated-Support",
            },
            {
                number: "03",
                title: "User-Centric Approach",
                para: "We craft strategies and solutions with a deep understanding and consideration of each user's unique needs and challenges.",
                img: "User-Centric-Approach",
            },
        ],
    },
};
