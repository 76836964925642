export const student_living = {
    meta_title: "Advanced fiber optic for student learning and living - Niurix",
    meta_description: "Enjoy Niurix as a fiber optic solution with an FTTR connection, offering triple play services to every room without a mesh of wires to create a single network.",

    section1: {
        banner_img: "Student Living Banner",
        banner_img_mobile: "Student banner",
        alt:"Experience lag free learning with fiber optic solution",
        title: "Fiber Optic Solutions for Learning: Get the Speed You Need to Succeed.",
    },
    section2: {
        img: "STUDENT LIVING1",
        alt:"Unleash the power of fast, reliable fiber optic network",
        title: "A network that fits all - <span>Redesigning the future of learning</span>",
        subtitle: "Lorem_Subtitle",
        desc: "Fast and reliable internet network is a need for modern day student living setting not only for learning but for entertainment. Introducing Niurix as a fiber optic solution, offering triple play services to every room without a mesh of wires to create a single network.",
    },

    section3: [
        {
            device_img: "ISP Router",
            device_title: "Router",
            alt:"Wireless router for internet connectivity."
        },

        {
            device_img: "olt",
            device_title: "OLT",
            alt:"OLT equipment for fiber optic network."
        },

        {
            device_img: "Splitter",
            device_title: "Splitter",
            alt:"Fiber optic splitter for network distribution."
        },

        {
            device_img: "Internet traffic",
            device_title: "Internet traffic",
            alt:"Icon symbolizing high-speed internet traffic flow."
        },

        {
            device_img: "1G Fiber",
            device_title: "1G Fiber",
            alt:"1G Fiber optic cable for high-speed internet."
        },

        {
            device_img: "Ethernet cable",
            device_title: "Ethernet cable",
            alt:"Ethernet cable for wired network connection."
        },

        {
            device_img: "ONT",
            device_title: "ONT",
            alt:"ONT device for fiber optic network."
        },

        {
            device_img: "IPTV",
            device_title: "IPTV",
            alt:"IPTV set-top box for internet television."
        },

        {
            device_img: "Router",
            device_title: "Access Point",
            alt:" Wireless access point for network connectivity."
        },

        {
            device_img: "telephone",
            device_title: "telephone",
            alt:" Telephone handset for voice communication."
        },

        {
            device_img: "wireless",
            device_title: "Wireless",
            alt:" Wireless signal icon for network connectivity."
        },
    ],
    section4: {
        title: "Advantages of Niurix Student Living Solutions",
        desc: "Modern learning requires high-speed, reliable internet. Harness uninterrupted and secure connection of FTTR (Fiber to the Room) student living solution from Niurix to unlock productivity without any delays.",
        cards: [
            {
                cards_img: "Reliable-Connectivity",
                cards_title: "Reliable Connectivity",
                alt:"Make learnings uninterrupted with trusted fiber connectivity"
                // cards_desc: "Access an extensive range of the latest generation in wireless connectivity along with wired connectivity solutions.",
            },

            {
                cards_img: "Advanced-Network-Security",
                cards_title: "Advanced Network Security",
                alt:"Stay safe surfing online with our high-end network security"
                // cards_desc: "Experience more secure connectivity with fiber optics and prevent student's sensitive data.",
            },

            {
                cards_img: "Differentiate Competitively",
                cards_title: "Differentiate Competitively",
                alt:"Give student learning space an edge with fiber networks"
                // cards_desc: "Offering students a unique and fast network solution sets student living communities apart from competitors.",
            },

            {
                cards_img: "Easy-Maintenance",
                cards_title: "Easy Maintenance",
                alt:"Bring a fiber network technology that is easy to maintain"
                // cards_desc: "Hassle-free functioning for smooth operations without easy corrosion and minimum signal loss issues.",
            },
        ],
    },
    // section5: {
    //     img: "contact banner",
    //     title: "Lorem",
    // },
};
