export const hospitality = {
    meta_title: "Elevate guest experience with next-gen fiber optic - Niurix",
    meta_description: "Centralized Wi-Fi, real-time network insights, future-proof technology. Niurix's FTTR gives you the ultimate hotel network tailored to enhance operations.",

    section1: {
        banner_img: "Hospitality Banner",
        banner_img_mobile: "Hosp Banner",
        alt:"Fiber network solution to enhance hospitality experience",
        title: "Catering to Exceptional Guest Experiences: Pioneering Next-Gen Networking Solutions to Redefine Hospitality.",
    },
    section2: {
        img: "HOSPITALITY1",
        alt:"Enhance your hospitality services using our fiber network",
        title: `Enabling <span>Uninterrupted Connectivity</span> - Around the Clock, <span>Anywhere</span>.`,
        subtitle: "Lorem_Subtitle",
        desc: "A fiber optic solution designed to meet the needs of hospitality businesses of today and tomorrow. With Niurix, let your customers have the ultimate guest experience by empowering your property with a next-gen solution.",
    },

    section3: [
        {
            device_img: "ISP Router",
            device_title: "Router",
            alt:"Wireless router for internet connectivity."
        },

        {
            device_img: "olt",
            device_title: "OLT",
            alt:"OLT equipment for fiber optic network."
        },

        {
            device_img: "Splitter",
            device_title: "Splitter",
            alt:"Fiber optic splitter for network distribution."
        },

        {
            device_img: "Internet traffic",
            device_title: "Internet traffic",
            alt:"Icon symbolizing high-speed internet traffic flow."
        },

        {
            device_img: "1G Fiber",
            device_title: "1G Fiber",
            alt:"1G Fiber optic cable for high-speed internet."
        },

        {
            device_img: "Ethernet cable",
            device_title: "Ethernet cable",
            alt:"Ethernet cable for wired network connection."
        },

        {
            device_img: "ONT",
            device_title: "ONT",
            alt:"ONT device for fiber optic network."
        },

        {
            device_img: "IPTV",
            device_title: "IPTV",
            alt:"IPTV set-top box for internet television."
        },

        {
            device_img: "Router",
            device_title: "Access Point",
            alt:" Wireless access point for network connectivity."
        },

        {
            device_img: "telephone",
            device_title: "telephone",
            alt:" Telephone handset for voice communication."
        },

        {
            device_img: "wireless",
            device_title: "Wireless",
            alt:" Wireless signal icon for network connectivity."
        },
    ],
    section4: {
        title: "Advantages of Niurix Hospitality Solutions",
        desc: "Seamless and high-speed internet is one of the most crucial factors in enhancing guest satisfaction for hotel services. Niurix's FTTR (Fiber to the Room) hospitality solution paves the way for you to elevate customer expectations, foster loyalty, and boost revenue, all in one go.",
        cards: [
            {
                cards_img: "Budgetary-Efficiency",
                cards_title: "Budgetary Efficiency",
                alt:"Fiber-to-the-room elevates budgetary efficiency"
                // cards_desc: "Reduce cost and in turn your working capital with the energy efficient networking solution.",
            },

            {
                cards_img: "Triple-Play-Services",
                cards_title: "Triple Play Services",
                alt:"Fiber optics of Niurix cover internet, television and voice"
                // cards_desc: "Easy integration of video, voice, and networking devices in one room without the jungle of wires.",
            },

            {
                cards_img: "Structured-Spatial-Layout",
                cards_title: "Structured Spatial Layout",
                alt:"Detangle your network with Fiber optic structured cables"
                // cards_desc: "Create a clean and harmonized guest space by getting rid of all the unwanted home run cables.",
            },

            {
                cards_img: "Single-Configuration-Deployment",
                cards_title: "Single Configuration Deployment",
                alt:"Easy fiber installation with single configuration deployment"
                // cards_desc: "Seamless, quick and one-time deployment by the experts without hindering the business operations.",
            },
        ],
    },
    // section5: {
    //     img: "contact banner",
    //     title: "Loremm",
    // },
};
