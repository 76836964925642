export const olt_solt33_16p = {
    meta_title: "Compact design, big impact: Niurix SOLT33-16P with 16 PoE Ports ",
    meta_description: "Handle multiple data flows and traffic easily with Niurix SOLT33-16P. Featuring higher switching capacity, it is fit for medium to large businesses and facilities.",

    section1: {
        background_img: "SOLT33-16P",
        background_img_mobile: "Solt3316p banner",
        alt:"Niurix SOLT33-16P rack/wall mount OLT, 16 GPON ports, FTTB"
    },

    section2: {
        mainHeading: `<span>Our</span> Product (OLTs)`,
        subHeading: "Niurix SOLT33-16P",
        mainPara: "",
        para: `<p>The Niurix SOLT33-16P is another Optical Line Terminal designed with 16 GPON ports that can be connected to ONTs. Its primary distinction from the SOLT33-08P is its increased number of GPON ports making it suitable for larger deployment for FTTB.\n\nThe simultaneous data switching capacity of SOLT33-16P is far-fetched than SOLT33-08P. The Niurix SOLT33-16P OLT functions with advanced technology, processors, and memory, enabling it to handle multiple data flows and traffic easily from the ONTs.</p> `,
        background_img1: "Overview background",
        background_img1_mobile: "Product solt 16 backg",
        alt:"Niurix SOLT33-16P OLT, 16 GPON with data switching and FTTB"
    },
    section3: {
        mainHeading: `<span>Connect</span> Effortlessly, Anytime, <span>Anywhere!</span>`,
        // subHeading1: "subheading1",
        para1: `<p>The rack/wall-mount design ensures better space management in server rooms, data centers, etc. SOLT33-16P is programmed to customize as per operator requirements to assist them in seamless monitoring and management of the premises.\n\nWith the ability to handle huge network deployment, the product is deemed fit for medium to large-sized businesses and multi-dwelling units (MDUs) with a flow of higher user density. </p> `,
        subHeading2: "The Twist Towards Simpler Connectivity",
        para2: `<ul><li>4 Ethernet port</li><li>1 GPON Uplink Port</li><li>... etc. ...</li></ul>`,
        background_img: "Component solt33-16p",
    },
    section4: {
        background_img: "Detail",
        background_img_mobile: "details 16 p",
        alt:"Niurix SOLT33-16P OLT with ports, optical/electrical uplinks",
        title: "The Twist Towards Simpler Connectivity",
        text_content_groups: [
            {
                title: "Higher Switching Capacity",
                para: "Ensure seamless data flow and robust connectivity with enhanced network performance.",
            },
            {
                title: "Wall/rack-mountable",
                para: "Conveniently mount on walls or racks by adapting to diverse network infrastructures and configurations.",
            },
            {
                title: "Additional GPON Ports",
                para: "Encourage more devices to be connected to the network with expanded connectivity options with extra GPON ports",
            },
        ],
    },
    section5: [
        {
            title: "General Product Specifications of Saturn SOLT33-16P OLT",
            specifications: "Specification",
            dimension: "Dimensions",
            specification: [
                {
                    title: "GPON Uplink Ports",
                    value: "16 Class B+ and C+ SFP Supported",
                },
                {
                    title: "Optical/Electrical uplink ports",
                    value: "04",
                },
                {
                    title: "Analog Phone Ports",
                    value: "None",
                },
                {
                    title: "CATV Ports",
                    value: "None",
                },
                {
                    title: "Power Supply",
                    value: "AC : 90 - 264 V, 47/63 Hz , DC : 36 - 72 V",
                },
                {
                    title: "Power Consumption",
                    value: "⩽ 65 Watts",
                },
                {
                    title: "Switching Capacity ",
                    value: "128 Gbps",
                },
            ],
            dimensions: [
                {
                    title: "Length",
                    value: "445mm",
                },
                {
                    title: "Width",
                    value: "383mm",
                },
                {
                    title: "Height",
                    value: "44mm",
                },
                {
                    title: "Weight",
                    value: "⩽ 2.8kg",
                },
            ],
            para: "Click to download the full specifications",
            pdf: "NiurixSOLT33-16POLT",
        },
    ],
    swipper: {
        img1: "Spec 1",
        alt1:"Niurix SOLT33-16P OLT back view with ports and uplinks",
        img2: "Spec 2",
        alt2:"Niurix SOLT33-16P OLT side view with air circulation vents",
        img3: "Spec 3",
        alt3:"Niurix SOLT33-16P OLT front view with yellow energy label",
        img4: "Spec 4",
        alt4:"Niurix SOLT33-16P OLT device, 16 GPON, top view with labels",
    },
    section6: {
        video: "Video",
    },
    section7: {
        background_img: "contact banner",
        title: "section5",
    },
};
