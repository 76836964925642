export const ont_g2410 = {
    meta_title: "Niurix G2410 - compact yet powerful ONT tech for networking",
    meta_description: "Niurix G2410, a lightweight, compact, and high-performance networking device with four Gigabit Ethernet ports that is light on pocket and consumes less energy.",

    section1: {
        background_img: "g2410 banner",
        background_img_mobile: "g2410 banner",
        alt:"Niurix G2410 compact and lightweight ONT with FTTH deployment"
    },

    section2: {
        mainHeading: `<span>Our</span> Product (ONTs)`,
        subHeading: "Niurix G2410",
        para: `<p>The Niurix G2410 ONT (Optical Network Terminal) is a lightweight, compact, and high-performance networking device with four Gigabit Ethernet(GbE) ports. This ONT is engineered to bridge the communication between modern technological infrastructure and end-users.\n\n The GPON (Gigabit-Capable Passive Optical Network) terminal converts optical signals into electrical signals used to connect multiple devices to the internet. Typically used in FTTH (Fiber to the Home) deployments, the G2410 is phenomenal in providing IP-based services such as internet access and VoIP (Voice over Internet Protocol) to any premises.</p>`,
        background_img1: "Overview background",
        background_img1_mobile: "Product g2410 backg",
        alt:"Niurix G2410 ONT device with FTTH with backdrop of a house"
    },
    section3: {
        mainHeading: `<span>Connect</span> Effortlessly, Anytime, <span>Anywhere!</span>`,
        // subHeading1: "Versatility and seamless integration",
        para1: `<p>The 4-port ONTs typically have one SFP (Small Form-factor Pluggable) port, which is used to connect the ONT to the fiber optic cable. Governed by Ethernet networking technologies, it is secure, scalable, and offers remote management, shielding the network from unauthorized access and access.\n\nThe availability of end-to-end connections in G2410 ONT ensures uninterrupted service for any user. The small, wall-mounted G2410 is inexpensive in the pocket and also consumes less power due to its close-knit features catering for IP devices only. They are less susceptible to interference and outages for enhanced high-performance connectivity.<p>`,
        subHeading2: "The Twist Towards Simpler Connectivity",
        para2: `<ul><li>4 Ethernet port</li><li>1 GPON Uplink Port</li></ul>`,
        background_img: "Component G2410",
    },
    section4: {
        background_img: "Details",
        background_img_mobile: "details g2410",
        alt:"Niurix G2410 ONT back view with DC power and Ethernet ports",
        title: "The Twist Towards Simpler Connectivity",
        text_content_groups: [
            {
                title: "Lesser Power Consumption",
                para: "They reduce power consumption to maximize sustainability with energy-efficient operation module.",
            },
            {
                title: "Compact Design",
                para: "The compact form optimizes space utilization and facilitates seamless integration into network setups.",
            },
            {
                title: "Fits Every Budget",
                para: "They are cost-effective, making them a good choice for budget-conscious businesses and consumers.",
            },
        ],
    },
    section5: [
        {
            title: "General Product Specifications of Niurix G2410 ONT",
            specifications: "Specification",
            dimension: "Dimensions",
            specification: [
                {
                    title: "GPON Uplink Ports",
                    value: "01",
                },
                {
                    title: "GE Ethernet Ports",
                    value: "04/PoE",
                },
                {
                    title: "Analog Phone Ports",
                    value: "None",
                },
                {
                    title: "CATV Ports",
                    value: "None",
                },
                {
                    title: "Power Supply",
                    value: "52 Vdc, 1.8 A",
                },
                {
                    title: "Power Consumption",
                    value: "90 Watts",
                },
            ],
            dimensions: [
                {
                    title: "Length",
                    value: "110mm",
                },
                {
                    title: "Width",
                    value: "110mm",
                },
                {
                    title: "Height",
                    value: "46mm",
                },
                {
                    title: "Weight",
                    value: "350g",
                },
            ],
            para: "Click to download the full specifications",
            pdf: "Niurix-G2110",
        },
    ],

    swipper: {
        img1: "Spec 1",
        alt1:"Niurix G2410 ONT top view with indicator lights of ports",
        img2: "Spec 2",
        alt2:"Niurix G2410 ONT with DC power and Ethernet ports at back",
        img3: "Spec 3",
        alt3:"Niurix G2410 ONT device left-side view with mounting bracket",
        img4: "Spec 4",
        alt4:"Niurix G2410 ONT right side view with mounting bracket",
    },
    section6: {
        video: "Video",
    },
    section7: {
        background_img: "contact banner",
        title: "section5",
    },
};
