export const ont_p4200r = {
    meta_title: "Experience Gigabit Speeds with Niurix ONT P4200R advantage",
    meta_description: "Connect multiple devices & enjoy triple play services with ONT P4200R from Niurix featuring security & scalability with high performance & remote monitorin.",

    section1: {
        background_img: "p4200R banner",
        background_img_mobile: "p4200r banner",
        alt:"Niurix P4200R full-feature ONT with triple play ports, FTTB"
    },

    section2: {
        mainHeading: `<span>Our</span> Product (ONTs)`,
        subHeading: "Niurix P4200R",
        mainPara: "",
        para: `The Niurix P4200R (Optical Network Terminal) is a full-feature ONT exclusively designed for triple-play services (internet, television, and voice). Typically consisting of 4 Gigabit Ethernet(GbE)/PoE ports can be used extensively for connecting multiple devices over the internet. \n\nP4200R's broad features include one CATV port, and two analog phone ports, and houses the ability to power PoE devices through the ONT. This desktop ONT is commonly used in FTTB (Fiber to the Building) for simplifying FTTR (Fiber to the Room) services.`,
        background_img1: "overview background",
        background_img1_mobile: "Product P4200R backg",
        alt:"Niurix P4200R ONT device with FTTB and backdrop of a house"
    },
    section3: {
        mainHeading: `<span>Connect</span> Effortlessly, Anytime, <span>Anywhere!</span>`,
        // subHeading1: "subheading1",
        para1: `<p>The GPON supported ONT is similar to G2410 and has high speed of 1Gbps/port with low latency making it ideal for connecting it to streaming videos and gaming. The P4200R enables remote configuration and management, making it scalable for multiple users on a premise.\n\nThe P4200R offers advanced security features like port filtering, firewall and DHCP, making it ideal for connecting all devices enclosed in a building system. The Niurix P4200R ONT is versatile and is viable to connect analog  phones and CATV as well.<p>`,
        subHeading2: "The Twist Towards Simpler Connectivity",
        para2: `<ul><li>4 Ethernet port</li><li>1 GPON Uplink Port</li><li>... etc. ...</li></ul>`,
        background_img: "Component p4200r",
        
    },
    section4: {
        background_img: "details",
        background_img_mobile: "details p4200",
        alt:"Niurix P4200R ONT back view with analog and Ethernet ports",
        title: "The Twist Towards Simpler Connectivity",
        text_content_groups: [
            {
                title: "Secure and Scalable",
                para: "The solution provides robust security measures along with easy scalability to adapt to evolving network needs.",
            },
            {
                title: "Higher Performance",
                para: "Experience seamless network performance to ensure smooth and efficient data transmission and communication.",
            },
            {
                title: "Remote Monitoring",
                para: "Through remote monitoring, gain real-time insights and control, and ensure network stability and reliability from anywhere.",
            },
        ],
    },
    section5: [
        {
            title: "General Product Specifications of Niurix P4200R ONT",
            specifications: "Specification",
            dimension: "Dimensions",
            specification: [
                {
                    title: "GPON Uplink Ports",
                    value: "01",
                },
                {
                    title: "GE Ethernet Ports",
                    value: "04/PoE",
                },
                {
                    title: "Analog Phone Ports",
                    value: "2",
                },
                {
                    title: "CATV Ports",
                    value: "1",
                },
                {
                    title: "Power Supply",
                    value: "52 Vdc, 1.8 A",
                },
                {
                    title: "Power Consumption",
                    value: "90 Watts",
                },
            ],
            dimensions: [
                {
                    title: "Length",
                    value: "210mm",
                },
                {
                    title: "Width",
                    value: "135mm",
                },
                {
                    title: "Height",
                    value: "30mm",
                },
                {
                    title: "Weight",
                    value: "400g",
                },
            ],
            para: "Click to download the full specifications",
            pdf: "Niurix-P4200R",
        },
    ],
    swipper: {
        img1: "spec 1",
        alt1:"Niurix P4200R ONT top view with indicator lights of ports",
        img2: "spec 2",
        alt2:"Niurix P4200R ONT with analog and Ethernet ports at back",
        img3: "spec 3",
        alt3:"Niurix P4200R ONT triple-play device in its right side view",
        img4: "spec 4",
        alt4:"Niurix P4200R ONT left side view with mounting bracket",
    },
    section6: {
        video: "Video",
       
    },
    section7: {
        img: "contact banner",
        title: "section5",
    },
};
