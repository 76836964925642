export const corporate_workspaces = {
    meta_title: "Niurix: Where Security, Integration, and Performance Converge",
    meta_description: "High data protection, improved performance, low latency, and smooth FTTD connection, experience all of this in Niurix powered fiber optic workspace.",

    section1: {
        banner_img: "Corporate Banner",
        banner_img_mobile: "Corporate banner",
        title: "Corporate Workspaces that Work: Reimagine Connectivity Solutions for Modern Workforce.",
        alt:"Modern workplace powered by fiber-optic networks"
    },
    section2: {
        img: "CORPORATE WORKSPACES1",
        alt:"Experience low latency, high performance fiber optic network",
        title: "Connecting buildings to fiber; <span>easy, reliable and fast.</span>",
        subtitle: "Lorem_Subtitle",
        desc: "High data protection, improved performance, low latency, and reliable, experience all of this in a Niurix powered fiber optic workspace. Conduct video conferencing, cloud computing, and access real-time data within seconds.",
    },

    section3: [
        {
            device_img: "ISP Router",
            device_title: "Router",
            alt:"Wireless router for internet connectivity."
        },

        {
            device_img: "olt",
            device_title: "OLT",
            alt:"OLT equipment for fiber optic network."
        },

        {
            device_img: "Splitter",
            device_title: "Splitter",
            alt:"Fiber optic splitter for network distribution."
        },

        {
            device_img: "Internet traffic",
            device_title: "Internet traffic",
            alt:"Icon symbolizing high-speed internet traffic flow."
        },

        {
            device_img: "1G Fiber",
            device_title: "1G Fiber",
            alt:"1G Fiber optic cable for high-speed internet."
        },

        {
            device_img: "Ethernet cable",
            device_title: "Ethernet cable",
            alt:"Ethernet cable for wired network connection."
        },

        {
            device_img: "ONT",
            device_title: "ONT",
            alt:"ONT device for fiber optic network."
        },

        {
            device_img: "IPTV",
            device_title: "IPTV",
            alt:"IPTV set-top box for internet television."
        },

        {
            device_img: "Router",
            device_title: "Access Point",
            alt:" Wireless access point for network connectivity."
        },

        {
            device_img: "telephone",
            device_title: "telephone",
            alt:" Telephone handset for voice communication."
        },

        {
            device_img: "wireless",
            device_title: "Wireless",
            alt:" Wireless signal icon for network connectivity."
        },
    ],
    section4: {
        title: "Advantages of Niurix Corporate Workforce Solutions",
        desc: "A fast-running internet connection is like blood for any workspace. Niurix's FTTD (Fiber to the Desk) solution offers technologically advanced and aesthetically pleasing workspace where enhanced productivity is the result of uninterrupted communication and data flow.",
        cards: [
            {
                cards_img: "Integration-with-External-Networks",
                cards_title: "Integration with External Networks",
                alt:"Effortlessly integrate your fiber with external networks"
                // cards_desc: "Supports all data carrying devices like surveillance cameras, routers, switches, etc. to create one network that connects all.",
            },

            {
                cards_img: "Seamlessly-Connected",
                cards_title: "Seamlessly Connected",
                alt:"Connect your devices seamlessly with our fiber network"
                // cards_desc: "Connection, configuration and credential sharing is done to ethernet or wireless devices easily.",
            },

            {
                cards_img: "Gigabit Level Network Velocity",
                cards_title: "Gigabit Level Network Velocity",
                alt:"Enhanced network bandwidth for faster device connectivity"
                // cards_desc: "Low latency, high speed network anytime to support high bandwidth applications compared to copper-based network.",
            },

            {
                cards_img: "Designed-to-Maximize-Performance",
                cards_title: "Designed to Maximize Performance",
                alt:"Get fiber optic network to optimize performance"
                // cards_desc: "Deliver enterprise-class solution, enabling deployments in even the most challenging network environments.",
            },
        ],
    },
    // section5: {
    //     img: "contact banner",
    //     title: "Lorem",
    // },
};
