export const olt_solt33_08p = {
    meta_title: "Niurix SOLT33-08P: 8-Port powerhouse for powerful performance",
    meta_description: "The Niurix SOLT33-08P serves up to 128 ONTs per PON port. Its high-speed internet, compact design & energy saver feature is ideal for small businesses and houses.",

    section1: {
        background_img: "SOLT33-08P banner",
        background_img_mobile: "Group 168215",
        alt:"Niurix SOLT33-08P compact OLT, 128 ONT/PON, fast internet"
    },

    section2: {
        mainHeading: `<span>Our</span> Product (OLTs)`,
        subHeading: "Niurix SOLT33-08P",
        mainPara: "",
        para: `<p>The Niurix SOLT33-08P is an Optical Line Terminal (OLT) designed for GPON networks serving up to 128 Optical Network Terminals (ONTs) per PON port. It consists of eight GPON ports, 4 GE Optical/Electrical uplink ports, along with two 10 Gigabit Ethernet (10GE) uplink ports.\n\nThe higher data throughput in SOLT33-08P serves as a critical component in delivering high-speed internet and other services to end-users. The device has a sleek, compact wall-mount design and can predetermine the simultaneous data management helping you better align your resources to cater to the needs of the OLT.</p>`,
        background_img1: "Overview background",
        background_img1_mobile: "Product Solt 08 backg",
        alt:"Niurix SOLT33-08P OLT with 8 GPON and 2x14 GE uplink ports"
    },
    section3: {
        mainHeading: `<span>Connect</span> Effortlessly, Anytime, <span>Anywhere!</span>`,
        // subHeading1: "subheading1",
        para1: `<p>The compact size makes it compatible for small to medium-sized businesses or deployments and multi-dwelling units (MDUs). The availability of 8 GPON ports makes SOLT33-08P more practical consuming lesser power. It also has a full-fledged carrier-class reliability with a complete set of security features.\n\nThe Niurix SOLT33-08P is widely used to cater to the custom requirements of LCOs (Local Cable Operators) and ISPs (Internet Service Providers) due to its flexible features and QoS features.</p>`,
        subHeading2: "The Twist Towards Simpler Connectivity",
        para2: `<ul><li>4 Ethernet port</li><li>1 GPON Uplink Port</li><li>... etc. ...</li></ul>`,
        background_img: "Component solt33-8p",
    },
    section4: {
        background_img: "Detail",
        background_img_mobile: "details 8 p",
        alt:"Niurix SOLT33-08P OLT with port and power supply details",
        title: "The Twist Towards Simpler Connectivity",
        text_content_groups: [
            {
                title: "Compact Design",
                para: "Their compact design is beneficial for saving space for optimizing installation and deployment flexibility.",
            },
            {
                title: "Wall/rack-mountable",
                para: "They can be conveniently mounted on walls or racks, adapting to various network infrastructure designs.",
            },
            {
                title: "Low Power Consumption",
                para: "Their energy-efficient design contributes to enhanced cost savings and environmental sustainability.",
            },
        ],
    },
    section5: [
        {
            title: "General Product Specifications of Saturn SOLT33-08P OLT ",
            specifications: "Specification",
            dimension: "Dimensions",
            specification: [
                {
                    title: "GPON Uplink Ports",
                    value: "08",
                },
                {
                    title: "GE Ethernet Ports",
                    value: "04",
                },
                {
                    title: "Analog Phone Ports",
                    value: "None",
                },
                {
                    title: "CATV Ports",
                    value: "None",
                },
                {
                    title: "Power Supply",
                    value: "AC : 90 - 264 V, 47/63 Hz , DC : 36 - 72 V",
                },
                {
                    title: "Power Consumption",
                    value: "50 Watts",
                },
            ],
            dimensions: [
                {
                    title: "Length",
                    value: "445mm",
                },
                {
                    title: "Width",
                    value: "227mm",
                },
                {
                    title: "Height",
                    value: "44mm",
                },
                {
                    title: "Weight",
                    value: "⩽ 2.8kg",
                },
            ],
            para: "Click to download the full specifications",
            pdf: "NiurixSOLT33-08POLT",
        },
    ],
    // swiper: [
    //     {
    //         img: "Spec 1",
    //         alt1:"Niurix SOLT33-08P OLT with network and power supply ports"
    //     },
    //     {
    //         img: "Spec 2",
    //         alt2:"Niurix SOLT33-08P OLT front view with yellow energy label"
    //     },
    //     {
    //         img: "Spec 3",
    //         alt3:"Niurix SOLT33-08P OLT side view with 3 air circulation vents"
    //     },
    //     {
    //         img: "Spec 4",
    //         alt4:"Niurix SOLT33-08P compact, wall mount OLT device in top view"
    //     },
    // ],
    swipper: {
        img1: "Spec 1",
        alt1:"Niurix SOLT33-08P OLT with network and power supply ports",
        img2: "Spec 2",
        alt2:"Niurix SOLT33-08P OLT front view with yellow energy label",
        img3: "Spec 3",
        alt3:"Niurix SOLT33-08P OLT side view with 3 air circulation vents",
        img4: "Spec 4",
        alt4:"Niurix SOLT33-08P compact, wall mount OLT device in top view"
    },
    section6: {
        video: "Video",
    },
    section7: {
        background_img: "contact banner",
        title: "section5",
    },
};
