export const residential_real_estate = {
    meta_title: "Future-proof connectivity with reliable niurix fiber network",
    meta_description: "Redefine modern living and upgrade your property with Niurix's FTTB network solution. Deliver blazing connectivity, SBM, and enhanced resident experiences.",

    section1: {
        banner_img: "Residential real estate banner",
        banner_img_mobile: "Resident banner",
        alt:"Harness power of fiber optics for smooth digital experience",
        title: "Reinventing Real-Estate Connectivity: Delivering Cutting Edge Fiber Solutions to Residential Spaces.",
    },
    section2: {
        img: "Residential REAL ESTATES1",
        alt:"Power of seamless fiber network and triple play services",
        title: "Solutions for <span>modern living</span> packed in <span>one box.</span>",
        subtitle: "Lorem_Subtitle",
        desc: "Experience the best possible connectivity in a seamless way and enjoy triple play services in the form of TV video, phone audio, and data. Once configured, leverage the power of harnessed data from anywhere in the building.",
    },

    section3: [
        {
            device_img: "ISP Router",
            device_title: "Router",
            alt:"Wireless router for internet connectivity."
        },

        {
            device_img: "olt",
            device_title: "OLT",
            alt:"OLT equipment for fiber optic network."
        },

        {
            device_img: "Splitter",
            device_title: "Splitter",
            alt:"Fiber optic splitter for network distribution."
        },

        {
            device_img: "Internet traffic",
            device_title: "Internet traffic",
            alt:"Icon symbolizing high-speed internet traffic flow."
        },

        {
            device_img: "1G Fiber",
            device_title: "1G Fiber",
            alt:"1G Fiber optic cable for high-speed internet."
        },

        {
            device_img: "Ethernet cable",
            device_title: "Ethernet cable",
            alt:"Ethernet cable for wired network connection."
        },

        {
            device_img: "ONT",
            device_title: "ONT",
            alt:"ONT device for fiber optic network."
        },

        {
            device_img: "IPTV",
            device_title: "IPTV",
            alt:"IPTV set-top box for internet television."
        },

        {
            device_img: "Router",
            device_title: "Access Point",
            alt:" Wireless access point for network connectivity."
        },

        {
            device_img: "telephone",
            device_title: "telephone",
            alt:" Telephone handset for voice communication."
        },

        {
            device_img: "wireless",
            device_title: "Wireless",
            alt:" Wireless signal icon for network connectivity."
        },
    ],
    section4: {
        title: "Advantages of Niurix Residential Real-Estate Solutions",
        desc: "Leverage the power of FTTB (Fiber to the Building) networking solution with Niurix and elevate your real estate operations by delivering lightning-fast connectivity, smart building management, and enhanced resident experiences. Redefine modern living and upgrade your property to new heights.",
        cards: [
            {
                cards_img: "Resilient-and-Reliable",
                cards_title: "Resilient and Reliable",
                alt:"Experience reliable and trustworthy network connection"
                // cards_desc: "Consistent and high-speed connectivity even during peak usage with bandwidth-hungry applications.",
            },

            {
                cards_img: "Prepared for Tomorrow",
                cards_title: "Prepared for Tomorrow",
                alt:"Get ready for the future with buildings equipped with fiber"
                // cards_desc: "Make your property in sync with future technologies and let Niurix handle future upgrades with ease.",
            },

            {
                cards_img: "Low-Latency",
                cards_title: "Low Latency",
                alt:"Low latency fiber network keeps your operations lag free"
                // cards_desc: "Experience transformational triple play services with minimum delay in data transfer for smooth entertainment.",
            },

            {
                cards_img: "Space-Efficient",
                cards_title: "Space Efficient",
                alt:" Free your workspace of tangled cables and make it efficient"
                // cards_desc: "A sleek and modest design without the mesh of wires to compliment your space without making its presence known.",
            },
        ],
    },
    // section5: {
    //     img: "contact banner",
    //     title: "Lorem",
    // },
};
